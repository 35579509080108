import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { ICreateAppointment } from "@interfaces/Appointment";

const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);
const appointmentApi = createApi({
  reducerPath: "appointmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem(
          `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
        )}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSpecialityAppointmentDays: builder.query<[], number>({
      query: (id) => `speciality/days/${id}`,
      // transformResponse: (response: any) => response,
      providesTags: [],
    }),

    getSlotsforDay: builder.query<any, { id: number; day: string }>({
      query: ({ id, day }) => `speciality/slots/${id}?date="${day}"`,
      // transformResponse: (response: any) => response,
      providesTags: [],
      // invalidatesTags: ['getDoctorAppointmentHours'],
    }),

    addAppointment: builder.mutation({
      query: (body) => {
        const appointment = {
          ...body,
          stationId: JSON.parse(
            localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`) ||
              ""
          ).id,
          centerId: JSON.parse(
            localStorage.getItem(
              `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`
            ) || ""
          ).id,
        };
        return {
          url: `/appointment`,
          method: "POST",
          body: appointment,
        };
      },
    }),
  }),
});

export const {
  useGetSpecialityAppointmentDaysQuery,
  useGetSlotsforDayQuery,
  useLazyGetSlotsforDayQuery,
  useAddAppointmentMutation,
} = appointmentApi;

export default appointmentApi;
