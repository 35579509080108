import React, { useState, useEffect, useRef } from "react";
import {
  DialogHeader,
  Typography,
  Button,
  Spinner,
  Dialog,
  Textarea,
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
} from "@material-tailwind/react";
import { Card } from "@material-tailwind/react";
import styled from "styled-components";
import { handleAnalyseAIClick } from "../../axiosAPI/AiRequests";

import {
  HomeIcon,
  BellIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import { MySkinAiModalType } from "../../types";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import { usePostAttachementMutation } from "../../features/Examen/advancedParamsApi";
import { useDispatch, useSelector } from "react-redux";
// import { updateAdvancedParams } from "../../features/Examen/advancedParamsSlice";
import APP_CONSTANTS from "../../constants";
import { CapturedDeviceType } from "../../enums/advancedParams";
import SelectListBox from "../FormBuilder/FormElements/SelectListBox";
import toast from "react-hot-toast";
import {
  setImage,
  setImageType,
  setPostData,
} from "../../features/Examen/advancedParamsSlice";
import { RootState } from "src/store";
import { useParams } from "react-router-dom";

//
export default function MySkinAiModal(props: MySkinAiModalType) {
  const { capturedImages, clickedDeviceIndex, fileType } = props;
  const [open, setOpen] = React.useState(false);
  const [showImage, setshowImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [aiResults, setAiResults] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [chosenImage, setChosenImage] = useState<File>();
  const attachements = useSelector((state: any) => state.advancedParamsData);
  const [postAttachement, { isLoading, isSuccess, isError }] =
    usePostAttachementMutation();
  const dispatch = useDispatch();
  const deviceTypeArray: string[] = Object.values(CapturedDeviceType);
  const storedUser = useSelector((state: RootState) => state?.auth?.user);
  const params = useParams();
  const [inputValue, setInputValue] = useState("");

  /** */
  const getAIResults = async (image: File) => {
    // clickedDeviceIndex check if it's equal to one
    const res = await handleAnalyseAIClick(image);
    if (res?.data?.probs) {
      console.log("my skin response", res);
      console.log("ai res", res?.data?.probs.slice(0, 3));
      const myskinAiData = res?.data?.probs.slice(0, 3);
      setAiResults(res?.data?.probs.slice(0, 3));
      // setOpen((cur) => !cur);
    } else {
      alert("Aucune peau détectée, veuillez essayer avec une autre image.");
    }
  };
  console.log("params", params);

  React.useMemo(() => {
    if (selectedImage) {
      setChosenImage(selectedImage);
      dispatch(setImage(selectedImage));
    }
  }, [selectedImage]);
  /** */

  const handleOpen = async (index: number, image: any) => {
    setSelectedImage(image);
    if (!open) {
      // console.log("file info", typeof image);
      setAiResults(null);
      setChosenImage(image);
      dispatch(
        setPostData({
          name: fileType,
          type: fileType,
          userId: localStorage.getItem(
            `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`
          ),
          specialityId: storedUser?.speacialityId,
          isScreenShot: true,
          consultationId: params.consultationId,
        })
      );
      setSelectedIndex(index);
      setOpen(true);
    }
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const containerRef = useRef<any>(null);

  useEffect(() => {
    // Scroll to the end when new images are added
    if (containerRef.current && capturedImages) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth + 10;
    }
  }, [capturedImages]);

  const handelSendData = () => {
    console.log("post Attachement", attachements);
    const data = new FormData();
    // data.append("file", chosenImage as Blob);
    data.append("file", attachements.image as Blob);
    data.append("name", attachements.otherData.name);
    data.append("type", attachements.otherData.type);
    data.append("userId", attachements.otherData.userId);
    data.append("specialityId", attachements.otherData.specialityId);
    data.append("isScreenShot", attachements.otherData.isScreenShot);
    data.append("consultationId", attachements.otherData.consultationId);
    data.append(
      "comment",
      `avis medecin generalist:${inputValue}.\r ${
        clickedDeviceIndex === 1 && aiResults && aiResults.length !==0
          ? "MySkinAI results:" +
            aiResults.map((result: any) => result[0]).join(",")
          : "."
      }.`
    );
    postAttachement(data);
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Image envoyée avec succès");
    }
    if (isError) {
      toast.error("Erreur lors de l'envoi de l'image");
    }
  }, [isLoading]);

  const handleShowImage = () => {
    setshowImage(!showImage);
  };

  const infoMessage = (
    <Typography
      variant="small"
      color="gray"
      className="mt-2 flex items-center gap-1 font-normal"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="-mt-px h-4 w-4"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clipRule="evenodd"
        />
      </svg>
      MySkinAI est la solution d'intelligence artificielle de MedIOT
    </Typography>
  );

  return (
    <React.Fragment key={clickedDeviceIndex}>
      <div
        className="flex overflow-y-auto flex-col mt-28 h-[52rem] gap-2 w-96"
        ref={containerRef}
      >
        {capturedImages &&
          capturedImages.map((image: any, index: number) => (
            <Card
              className="cursor-pointer overflow-hidden transition-opacity hover:opacity-90 flex-none mr-4"
              onClick={() => {
                console.log(image);
                if (image) {
                  handleOpen(index, image);
                } else {
                  toast.error("image no disponible");
                }
              }}
            >
              <div className="h-60 w-128 flex-shrink-0 ">
                {image ? (
                  <StyledImage
                    alt="analys"
                    className="h-full w-full object-cover object-center"
                    src={URL.createObjectURL(image)}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
            </Card>
          ))}
      </div>

      <Dialog open={open} handler={handleShowImage}>
        <DialogHeader>Resultats</DialogHeader>
        {capturedImages[selectedIndex] && (
          <>
            <img
              alt="nature"
              className="object-cover object-center h-[50%] w-[100%]"
              src={URL.createObjectURL(capturedImages[selectedIndex])}
            />
            {clickedDeviceIndex === 1 && !aiResults ? (
              <div className="items-center gap-3 m-5 ">
                <Button
                  variant="outlined"
                  className="flex w-full justify-center text-xl"
                  onClick={() => {
                    getAIResults(capturedImages[selectedIndex]);
                  }}
                >
                  MySkinAI
                </Button>
                {infoMessage}
              </div>
            ) : (
              clickedDeviceIndex === 1 && (
                <div className="items-center gap-3 m-5 ">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAiResults(null);
                    }}
                    className="flex w-full justify-center text-xl outline outline-offset-2 outline-red-500 text-red-500"
                  >
                    anuuler Resultat AI
                  </Button>
                  {infoMessage}
                </div>
              )
            )}
          </>
        )}
        <div className="items-center gap-3 m-5">
          {aiResults && clickedDeviceIndex === 1 && (
            <Timeline>
              {aiResults.map((result: any) => (
                <TimelineItem>
                  <TimelineConnector />
                  <TimelineHeader>
                    <TimelineIcon className="p-2"></TimelineIcon>
                    <Typography variant="h5" color="blue-gray">
                      {result[0]}
                    </Typography>
                  </TimelineHeader>
                  <TimelineBody className="pb-8">
                    <Typography
                      color="gary"
                      className="font-normal text-gray-600"
                    >
                      {""}
                    </Typography>
                  </TimelineBody>
                </TimelineItem>
              ))}
            </Timeline>
          )}
          <div className="items-center gap-3 m-5">
            {/* avis Specialist */}
            <Textarea
              size="lg"
              value={inputValue}
              onChange={handleInputChange}
              variant="outlined"
              label="Votre avis ...."
            />
          </div>
        </div>
        <div className="col-span-4 flex justify-between mt-5 m-6">
          <button
            type="button"
            className=" bg-[#858585] text-white w-36 px-5 py-2 rounded font-bold"
            onClick={() => setOpen(false)}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="bg-[#1AA5C0] text-white w-36 px-5 py-2 rounded font-bold"
            onClick={() => handelSendData()}
          >
            {!isLoading ? "Sauvgarder" : <Spinner />}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
const StyledImage = styled.img`
  cursor: pointer;

  animation: screenshot 1s ease-in-out reverse;
  /* border-radius: 20%; */

  @keyframes screenshot {
    to {
      transform: translate(-40rem, -20rem) scale(2);
    }
  }
`;
