import React, { useEffect, useState } from "react";
import "./btn.css";
import { getDoctorNamebyId, patchDisponibilite } from "../../axiosAPI/Network";
import { toast } from "react-hot-toast";
import { generateUniqueId } from "../../services/utils.service";
import APP_CONSTANTS from "../../constants";
import { BiReset } from 'react-icons/bi'
import { AiOutlineSave } from 'react-icons/ai'
const daysOfWeek = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
];
const index = () => {
    const [docId, setDocId] = useState<any>();
    const [disalbed, setDisabled] = useState<boolean>();
    const [intervalMinutes, setIntervalMinutes] = useState<any>();
    const [selectedSlotsByDay, setSelectedSlotsByDay] = useState<
        Record<string, string[]>
    >({});
    const [selectedSlots, setSelectedSlots] = useState<{
        [day: number]: string[];
    }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        return setDocId(
            window.localStorage.getItem(
                `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`
            )
        );
    });

    function convertTimeSlotsToSlotsArray(
        slotsData: Record<number, string[]>
    ): Record<number, string[]> {
        const result: Record<number, string[]> = {};

        for (const day in slotsData) {
            if (Object.prototype.hasOwnProperty.call(slotsData, day)) {
                const slots = slotsData[day];
                const formattedSlots: string[] = [];

                for (const slot of slots) {
                    const [start, end] = slot.split("|").map((time) => {
                        const date = new Date(time);
                        return `${String(date.getHours()).padStart(2, "0")}:${String(
                            date.getMinutes()
                        ).padStart(2, "0")}`;
                    });

                    formattedSlots.push(`${start}-${end}`);
                }

                result[parseInt(day)] = formattedSlots;
            }
        }

        return result;
    }

    const filterSlots = (slots: any[]) => {
        return slots.filter((slot: { split: (arg0: string) => [any, any]; }) => {
            const [startTime, endTime] = slot.split('-');
            const [hours, minutes] = endTime.split(':').map(Number);

            // Check if the slot ends before 18:00 (6:00 PM)
            return hours < 18 || (hours === 18 && minutes === 0);
        });
    }
    useEffect(() => {
        getDoctorNamebyId(docId).then((res: any) => {
            setIntervalMinutes(res?.speciality?.avgTime);

            console.log(res);
            if (res?.planning != null || undefined) {
                 const slotsArray = convertTimeSlotsToSlotsArray(res?.planning);
                // const slots = res?.planning
                console.log("🚀 ~ file: index.tsx:82 ~ getDoctorNamebyId ~ slots:", slotsArray)
             
                for (const key in slotsArray) {
                    slotsArray[key] = filterSlots(slotsArray[key]);
                }
                console.log(slotsArray)
                
                 setSelectedSlots(slotsArray);
                 setDisabled(true);
            }
        });
    }, [docId, selectedSlotsByDay]);

    const startTime = new Date();
    // Set start time
    startTime.setHours(8, 0, 0);

    const endTime = new Date();

    endTime.setHours(18, 0, 0);

    const timeSlotsByDay: {
        day: string;
        timeRange: string;
        startTimeFormatted: string;
    }[][] = [];

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const date = new Date();
        date.setDate(date.getDate() + dayIndex);

        const dayTimeSlots = [];
        let currentTime = new Date(startTime);

        while (currentTime < endTime) {
            const nextTime = new Date(
                currentTime.getTime() + intervalMinutes * 60 * 1000
            );

            const startTimeFormatted = currentTime.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
            const endTimeFormatted = nextTime.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });

            dayTimeSlots.push({
                day: daysOfWeek[dayIndex],
                timeRange: `${startTimeFormatted}-${endTimeFormatted}`,
                startTimeFormatted,
            });

            currentTime = nextTime;
        }

        timeSlotsByDay.push(dayTimeSlots);
    }
    const isTimeSlotSelected = (dayIndex: number, timeRange: string) => {
        return selectedSlots[dayIndex]?.includes(timeRange);
    };

    const toggleTimeSlotSelection = (dayIndex: number, timeRange: string) => {
        setSelectedSlots((prevSelectedSlots) => {
            const selectedDaySlots = prevSelectedSlots[dayIndex] || [];
            if (selectedDaySlots.includes(timeRange)) {
                return {
                    ...prevSelectedSlots,
                    [dayIndex]: selectedDaySlots.filter((slot) => slot !== timeRange),
                };
            } else {
                return {
                    ...prevSelectedSlots,
                    [dayIndex]: [...selectedDaySlots, timeRange],
                };
            }
        });
    };

    function convertSlotsToDatetime(
        slotsData: Record<number, string[]>
    ): Record<number, string[]> {
        const result: Record<number, string[]> = {};
        const currentDate = new Date();

        for (const day in slotsData) {
            if (Object.prototype.hasOwnProperty.call(slotsData, day)) {
                const slots = slotsData[day];

                // Check if the array of slots for the current day is not empty
                if (slots.length > 0) {
                    const formattedSlots: string[] = [];

                    for (const slot of slots) {
                        const [startTime, endTime] = slot.split("-").map((time) => {
                            const [hours, minutes] = time.split(":").map(Number);

                            // Use the current date and set the hours and minutes
                            const dateWithTime = new Date(currentDate);
                            dateWithTime.setHours(hours);
                            dateWithTime.setMinutes(minutes);

                            return dateWithTime.toISOString();
                        });

                        formattedSlots.push(`${startTime}|${endTime}`);
                    }

                    result[parseInt(day)] = formattedSlots;
                }
            }
        }

        return result;
    }

    const saveData = async () => {
        setIsLoading(true);
        const id = parseInt(docId!);
        const datetimeData = convertSlotsToDatetime(selectedSlots);
        console.log(datetimeData);
        patchDisponibilite(id, { planning: datetimeData }).then((res) => {
            if (res.status === 200) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
            }
        });
        toast.success(
            `Le planning de votre Disponibilité a été créé avec succès!`,
            {
                id: generateUniqueId(),
            }
        );
    };

    const maxSlots = Math.max(
        ...timeSlotsByDay.map((dayTimeSlots) => dayTimeSlots.length)
    );
    const processedData = timeSlotsByDay.map((dayTimeSlots) => {
        const missingSlots = maxSlots - dayTimeSlots.length;
        return [...dayTimeSlots, ...new Array(missingSlots).fill(null)];
    });
    console.log("selectedSlotsByDay ---->", selectedSlotsByDay);
    console.log("selectedSlots---->", selectedSlots);
    // console.log("processedData---->", processedData)
    const clearAllSlots = () => {
        setSelectedSlots({});
    };
    const isDaySelected = (dayIndex: number) => {
        return Object.keys(selectedSlots).some(
            (selectedDay) => parseInt(selectedDay) === dayIndex
        );
    };

    const toggleDaySelection = (dayIndex: number) => {
        if (isDaySelected(dayIndex)) {
            // Deselect all slots for the day
            setSelectedSlots((prevSelectedSlots) => {
                const updatedSelectedSlots = { ...prevSelectedSlots };
                delete updatedSelectedSlots[dayIndex];
                return updatedSelectedSlots;
            });
        } else {
            // Select all slots for the day
            setSelectedSlots((prevSelectedSlots) => {
                const updatedSelectedSlots = { ...prevSelectedSlots };
                updatedSelectedSlots[dayIndex] = timeSlotsByDay[dayIndex].map(
                    (slot) => slot.timeRange
                );
                return updatedSelectedSlots;
            });
        }
    };


    return (
        <div className="p-6">
            <h1 className="text-2xl text-Bleu font-semibold mb-4">Mes Disponibilités</h1>
            <div className="flex">
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="rounded-full">
                            {daysOfWeek.map((day, dayIndex) => (
                                <th key={day} className="mx-3 rounded-lg bg-[#335D84] p-3">
                                    <input
                                        className="mx-5 cursor-pointer w-6 h-6 text-[#1AA5C0] bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        type="checkbox"
                                        checked={isDaySelected(dayIndex)}
                                        onChange={() => toggleDaySelection(dayIndex)}
                                    />
                                    <span className="text-white">{day}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {processedData[0].map((_, timeIndex) => (
                            <tr key={timeIndex}>
                                {timeSlotsByDay?.map((dayTimeSlots, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        className={`border rounded-lg px-4 py-2 cursor-pointer align-center
                                    ${isTimeSlotSelected(
                                            dayIndex,
                                            dayTimeSlots[timeIndex].timeRange
                                        )
                                                ? "bg-Bleu text-white"
                                                : ""
                                            }`}
                                        onClick={() =>
                                            toggleTimeSlotSelection(
                                                dayIndex,
                                                dayTimeSlots[timeIndex].timeRange
                                            )
                                        }
                                    >
                                        <span className="flex justify-center">
                                            {dayTimeSlots[timeIndex].timeRange}
                                        </span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex justify-end">
                <button
                    onClick={clearAllSlots}
                    className="flex gap-3 text-white bg-red-600  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                    <BiReset size={20} />Réinitialiser
                </button>
                <button
                    onClick={saveData}
                    disabled={isLoading}
                    type="button"
                    className="flex gap-3 text-white bg-[#335D84] hover:bg-Bleu focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                    <AiOutlineSave size={20} />  Enregistrer
                </button>
            </div>
        </div>
    );
};

export default index;
