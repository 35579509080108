import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

const specialityApi = createApi({
  reducerPath: "specialityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getSpeciality: builder.query({
      query: () => `/speciality`,
      // providesTags: [],
      transformResponse: (response: any) => response?.results,
    }),
    getDaysOfSpeciality: builder.query({
      query: (id) => `/speciality/days/${id}`,
    }),
  }),
});

export const { useGetSpecialityQuery, useGetDaysOfSpecialityQuery } =
  specialityApi;

export default specialityApi;
