import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { IoIosArrowForward } from "react-icons/io";
import dayjs from "dayjs";
import { setRightIsOpen } from "../../features/sidebar/SidebarSlice";
import {
  cancelAppointment,
  getAppointmentsByDoctorId,
} from "../../axiosAPI/Network";
import WebSocketService from "./../../services/Notification/socketService";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import { useGetOnGoingPatientConsultationQuery } from "../../features/consultationData/consultationDataApi";
import { setLastPatientOngoingConsultation } from "../../features/consultationData/consultationDataSlice";

import { AppUserId, PATIENT_RECORD_TABS } from "../../constants";

import "dayjs/locale/fr";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import { PatientRecord } from "../../Components/patientRecord";
import { setCurrentPatient } from "../../features/patient/currentPatientSlice";
import APP_CONSTANTS from "../../constants";
import { setConsultationMetadata } from "../../features/consultationMetadata";
import { getBoxId, getCenterId, getGeneralistId } from "../../features/teleconsultation/CallInfosSlice";
import { NotifiedObject, updateCurrentNotifiedConsultation } from "../../features/call/notifiedConsultationSlice";
import { MedicationPrescriptions } from "../../Components/medicationsPrescription";
import { useGetConsultationOngoingCallQuery } from "../../features/call/callApi";
import { userStatus } from "../../enums/app";






// const centerId = ;
const Right = () => {
  dayjs.locale("fr");
  const { openDynamicDialog, closeDynamicDialog } = useDynamicDialog();
  const storedUser = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [isConsultationNotified, setIsConsultationNotified] = useState<boolean>(false)
  const [canCallCenter, setCanCallCenter] = useState<boolean>(false)

  const rightIsOpen = useSelector((state: RootState) => state.sidebar.rightIsOpen);
  const motif = useSelector((state: RootState) => state.sidebar.motif);
  const consultationMetadata = useSelector((state: RootState) => state.consultationMetadata);
  const currentPatient = useSelector((state: RootState) => state.currentPatient.currentPatient);
  const notifiedConsultationsArr: Array<NotifiedObject> = useSelector((state: RootState) => state?.notifiedConsultation.notifiedConsultations);
  const { data: patientOngoingConsultations } = useGetOnGoingPatientConsultationQuery(motif.patientId);


  /* patient ongoing call */
  const { data: onGoingCalls, isLoading: isLoadingOngoingCall, isError: isErrorOngoingCall } = useGetConsultationOngoingCallQuery(patientOngoingConsultations?.results[0]?.id);

  /** get all online users, including the ones who are in a call */
  const onlineUsers: Array<any> = useSelector((state: RootState) => state.auth.onlineUsers);

  // represent the userId of the generalist
  const [generalistId, setGeneralistId] = useState<any>();
  const [generalistStatus, setGeneralistStatus] = useState<userStatus>(userStatus.OFFLINE);
  const [stationId, setStationId] = useState<any>();

  const StartTime = dayjs(motif.start).format("hh:mm A");
  const EndTime = dayjs(motif.end).format("hh:mm A");
  const date = dayjs(motif.start).format("DD MMM YYYY");
  const currentNotifiedConsultation = useSelector((state: any) => state.notifiedConsultation.currentNotifiedConsultation);


  const [appointmentPatient, setAppointmentPatient] = useState<any>();



  const handleClose = () => {
    dispatch(setRightIsOpen(!rightIsOpen));
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  useEffect(() => {
    if (motif.patient) {
      const today = new Date();
      dispatch(setCurrentPatient(motif.patient));

      const { firstName, lastName, birthDate, gender } = motif.patient.user;
      setAppointmentPatient({
        firstName,
        lastName,
        birthDate,
        gender,
        age: today.getFullYear() - new Date(birthDate).getFullYear(),
      });
    }
  }, [motif]);

  useEffect(() => {
    console.log("online users:", onlineUsers, generalistId, storedUser);

    if (generalistId) {
      // socket check
      if (onlineUsers.length) { // cond 4

        const doctors = onlineUsers.filter((u: any) => u.userId == generalistId)

        if (doctors?.length) { // cond 5

          setGeneralistStatus(doctors[0].status);
        } else { // cond 9
          console.log("online users:generalist is in offline", doctors);

          setGeneralistStatus(userStatus.OFFLINE); return;
        }
      }
      else { // cond 10
        setGeneralistStatus(userStatus.OFFLINE);
        console.log("check 10:specialist is offline");
      }

    }
  }, [onlineUsers, generalistId])

  useEffect(() => {
    console.log("the generalist status is", generalistStatus)
  }, [generalistStatus])
  useEffect(() => {
    if (consultationMetadata && notifiedConsultationsArr.some(
      (consultation) => consultation.consultationId === consultationMetadata.patientOngoingConsultation?.id
    )) {
      console.log("this consultation was notified", consultationMetadata.patientOngoingConsultation?.id)
      setIsConsultationNotified(true);
    } else {
      console.log("this consultation was not notified", consultationMetadata.patientOngoingConsultation?.id)

      setIsConsultationNotified(false);
    }
  }, [consultationMetadata, notifiedConsultationsArr]);


  useEffect(() => {
    if (patientOngoingConsultations) {

      const lastPatientOngoingConsultation =
        patientOngoingConsultations?.results?.length > 0
          ? patientOngoingConsultations?.results[0]
          : null;
      dispatch(
        setLastPatientOngoingConsultation(lastPatientOngoingConsultation)
      );

      console.log("latest", lastPatientOngoingConsultation)


      // temporary store the consultation metadata in the localstorage
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`,
        JSON.stringify({
          stationId: motif?.stationId,
          patientId: motif?.patientId,
          isSpecialistNotifed: !!lastPatientOngoingConsultation,
          patientOngoingConsultation: lastPatientOngoingConsultation,
          userId: AppUserId,
          //TODO: should be consultationId:
          consultationId: lastPatientOngoingConsultation?.id,
          centerId: motif?.centerId,
        })
      );
      dispatch(setConsultationMetadata({
        stationId: motif.stationId,
        patientId: motif.patientId,
        isSpecialistNotifed: !!lastPatientOngoingConsultation,
        patientOngoingConsultation: lastPatientOngoingConsultation,
        userId: AppUserId,
        consultationId: lastPatientOngoingConsultation?.id,
        centerId: motif.centerId,
      }))

      dispatch(updateCurrentNotifiedConsultation(notifiedConsultationsArr.filter((x: NotifiedObject) => x.consultationId == lastPatientOngoingConsultation?.id)[0]))

    }
  }, [patientOngoingConsultations]);



  useEffect(() => {
    if (currentNotifiedConsultation) {
      setStationId(currentNotifiedConsultation?.stationId);
      setGeneralistId(currentNotifiedConsultation?.generalistId);
      setCanCallCenter(true);
    }
  }, [currentNotifiedConsultation])

  useEffect(() => {
    console.log("checking statusess ", isConsultationNotified
      , canCallCenter
      , !onGoingCalls?.results?.length
      , ![userStatus.OFFLINE, userStatus.IN_CALL, null].includes(generalistStatus))


  }, [isConsultationNotified, canCallCenter, onGoingCalls, generalistStatus])


  const handleCallPatientClick = () => {

    sendNotification(consultationMetadata.consultationId);

  };

  const handleOpenPatientRecordClick = () => {
    if (currentPatient) {
      openDynamicDialog({
        title: "Dossier patient",
        size: "xl",
        message: "",
        component: (<>

          {/* Patient Record */}
          <div className="my-2 w-full">
            <PatientRecord
              patientId={currentPatient.id}
              options={{
                enable: [
                  PATIENT_RECORD_TABS.PATIENT_FILE,
                  PATIENT_RECORD_TABS.MEDICAL_HISTORY,
                  PATIENT_RECORD_TABS.VITAL_SIGNS,
                  PATIENT_RECORD_TABS.BIOLOGY,
                  PATIENT_RECORD_TABS.REPORTS,
                ]
              }} />
          </div>
          {/*  END patient record */}
        </>),
        handleCancel: null,
        handleConfirm: null,
      });
    }
  };

  /**
 * 
 */
  const handleReturnToCall = () => {
    const callId = onGoingCalls.results[0].id;
    nav(`/specialiste/doctor/call/${callId}`)
  }


  /**
   * call tereatment
   * @param consultationId 
   */
  const sendNotification = (consultationId: number) => {

    // const generalistId =localStorage.getItem("generalistUserId");
    const notificationsData = {
      stationId: stationId,
      patientId: motif.patientId,
      generalist: { id: generalistId },
      specialist: { id: AppUserId },
      //TODO: should be consultationId:
      consultation: { "id": consultationId },
      centerId: motif.centerId,
    };

    console.log("notification Data", notificationsData);

    if (generalistId) {
      WebSocketService.sendAppointmentNotification(
        notificationsData,
        (data) => {
          //call post request returned data
          console.log("call post request res", data);
          const targetURL = `/specialiste/doctor/call/${data.id}`;
          nav(targetURL, { state: { uid: data.id } });
        }
      );
    }


  };
  const cancel = () => {
    cancelAppointment(motif.id, "cancelled");
    dispatch(setRightIsOpen(!rightIsOpen));
    setOpen(false);
    nav(`/specialiste/doctor`);
  };

  return (
    <>
      <div
        className={rightIsOpen ? "duration-300 h-screen relative" : "hidden"}
      >
        <div
          id="drawer-right-example"
          className="top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform transforme-none bg-white w-80 dark:bg-gray-800 duration-300 relative"
          tabIndex={-1}
          aria-labelledby="drawer-right-label"
        >
          <aside
            id="default-sidebar"
            className="fixed mr-0 right-10 top-0 z-40 w-80  h-screen
              sm:translate-x-10 overflow-y-auto transition-transform translate-x-full  mt-16
             "
            aria-label="Sidebar"
          >
            <div className="h-full px-3 py-4 overflow-y-auto bg-white dark:bg-gray-800">
              <ul className="space-y-2 font-medium">
                <li>
                  <div
                    className="inline-flex rounded-md shadow-sm"
                    role="group"
                  >
                    <button
                      onClick={handleClose}
                      type="button"
                      className="text-Bleu w-72 pr-24 bg-secondeWhite  focus:ring-4 focus:outline-none
                                 font-xl rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center
                                   "
                    >
                      <IoIosArrowForward className="mr-5" />
                    </button>
                  </div>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-start gap-3 flex-col p-2 cursor-default text-gray-900 rounded-lg dark:text-white dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1  flex-wrap text-[#5D7285]">
                      {date}
                    </span>
                    <span className="inline-flex items-start justify-start text-sm font-medium text-[#5D7285]">
                      {StartTime} {EndTime}
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="flex items-start p-2 text-gray-900 rounded-lg dark:text-white  dark:hover:bg-gray-700 group"
                  >
                    <span className="flex-1  whitespace-nowrap">
                      <p className="text-[#335D84]">Station</p>
                      <h6 className="text-[#5D7285] text-sm">{motif.title}</h6>
                    </span>
                  </a>
                </li>
                {motif?.patient && (
                  <li>
                    <span className="text-[#335D84]">
                      Informations sur le patient
                    </span>
                    <p className="text-sm text-[#5D7285]">
                      {appointmentPatient?.firstName}{" "}
                      {appointmentPatient?.lastName}
                    </p>
                    <p className="text-sm text-[#5D7285]">
                      {appointmentPatient?.age} {"ans"}
                    </p>
                    <p className="text-sm text-[#5D7285]">
                      {appointmentPatient?.gender == "male" ? "Homme" : "Femme"}
                    </p>
                  </li>
                )}
                {motif?.reason && (
                  <li>
                    <span className="text-[#335D84]">
                      Correspondance médicale
                    </span>
                    <p className="text-sm text-[#5D7285]">
                      {motif?.reason}

                    </p>

                  </li>
                )}

                <li className="mb-5">
                  <button
                    onClick={handleOpenPatientRecordClick}
                    type="button"
                    className="px-6 py-3.5 w-full text-base font-medium text-white items-center mt-5
                             bg-[#335D84]  focus:ring-4 focus:ring-blue-300  text-center"
                  >
                    Accéder au dossier patient
                  </button>
                </li>
                <hr />
                {/* call actions */}

                <li className="mt-5">

                  {/* button available only if the doctor notified the specialist */}
                  {(
                    isConsultationNotified
                    && canCallCenter
                    // && !onGoingCalls?.results?.length
                    && ![userStatus.OFFLINE, userStatus.IN_CALL, null].includes(generalistStatus)
                  ) && <button
                    type="button"
                    className="px-6 py-3.5 w-full text-base font-medium text-white items-center mt-3
                             bg-[#1AA5C0]  focus:ring-4 focus:ring-blue-300  text-center"
                    onClick={handleCallPatientClick}
                  >
                      Appeler le centre
                    </button>}



                  {(!isConsultationNotified
                    // && !onGoingCalls?.results?.length
                    ) && (
                      <div className="pflex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div>
                          <span className="font-medium">Note:</span> Veuillez patientez en attendant que le médecin géneraliste vous notifie.
                        </div>
                      </div>
                    )}


                  {/* return to  the doctor button */}
                  {(
                    onGoingCalls?.results?.length > 0
                    &&
                    generalistStatus === userStatus.IN_CALL
                  ) && (<div className="flex flex-col">
                    <span className="text-gray">Le médecin est déja dans l'appel, voulez vous: </span>
                    <button
                      type="button"
                      className="px-6 py-3.5 w-full text-base font-medium text-white items-center mt-3
                     bg-yellow-300  focus:ring-4 focus:ring-yellow-300  text-center"
                      onClick={handleReturnToCall}
                      color="text-white bg-yellow-400 mb-4 w-[16rem]"
                    >
                      Revenir à l'appel
                    </button>
                    <span className="text-center mx-auto"> Ou</span>

                    {/* button available only if the doctor notified the specialist */}
                    {(
                      isConsultationNotified
                      && canCallCenter

                    ) && <button
                      type="button"
                      className="px-6 py-3.5 w-full text-base font-medium text-white items-center mt-3
                             bg-[#1AA5C0]  focus:ring-4 focus:ring-blue-300  text-center"
                      onClick={handleCallPatientClick}
                    >
                        Efféctuer un nouveau appel
                      </button>}

                  </div>)
                  }


                  {/*  */}
                </li>

              </ul>
            </div>
          </aside>
        </div >
      </div >
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Alert !</DialogHeader>
        <DialogBody divider>
          êtes-vous sûr de vouloir annuler ce rendez-vous
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="teal"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
          <Button className="bg-[#1AA5C0]" color="green" onClick={cancel}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Right;
