import { SELECT_DATA_STATE, TAG_DATA_STATE } from "../../../../enums/stepper";
import { SLICE } from "../../../../enums/store";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import SearchableInput from "../../../../../src/Components/FormBuilder/FormElements/SearchableSelect";
import {
  addNewMedical,
  clearMedical,
  setAntecedentsMedicals,
  setFamilyOthers,
  setFamilySelected,
  setMedicalMotifs,
  setMedicalYear,
  setSurgicalMotifs,
  setSurgicalYear,
  setToxicsAlcohol,
  setToxicsOthers,
  setToxicsTabacBoxPerYear,
  setToxicsTabacCigarttesPerDay,
  setToxicsTabacDuration,
  setToxicsTabacType,
  spliceMedicalData,
} from "../../../../features/consultationData/consultationDataSlice";
import { setIsMedicals } from "../../../../features/medical/medicalSlice";
import Tag from "../../../../Components/Tag";
import RadioInput from "../../../../Components/FormBuilder/FormElements/Radio";
import SelectListBox from "../../../../Components/FormBuilder/FormElements/SelectListBox";
import { years } from "../../../../constants/stepper";
import { TrashIcon } from "@heroicons/react/24/solid";
import { ReactComponent as PlusSvg } from "../../../../assets/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { useGetConsultationDataQuery } from "../../../../features/consultationData/consultationDataApi";
import { useParams } from "react-router-dom";
import { setIsSurgicals } from "../../../../features/surgical/surgicalSlice";
import { setisTabacConsuming } from "../../../../features/isTabacConsuming/isTabacConsumingSlice";
import { setIsAlcoholic } from "../../../../features/alcoholic/alcoholSlice";
import { sethasOtherSubs } from "../../../../features/hasOtherSubs/hasOtherSubsSlice";
import {
  setAllergy,
  setIsAllergic,
} from "../../../../features/allergy/allergySlice";

export interface IMedicauxProps {}

export default function Medicaux(props: IMedicauxProps) {
  const { consultationId } = useParams();
  const { data: consultationQueriedData } = useGetConsultationDataQuery(
    Number(consultationId)
  );
  const storedMedical = useSelector(
    (state: any) =>
      state.consultationData?.consultationData?.medicalHistory?.medical
  );
  const dispatch = useDispatch();
  const [isMedical, setIsMedical] = React.useState(false);
  const [medicalList, setMedicalList] = React.useState<any>(null);

  const storedIsMedical = useSelector((state: any) => state.medical.isMedical);
  useEffect(() => {
    dispatch(setIsMedicals(isMedical));
  }, [isMedical]);
  useEffect(() => {
    if (consultationQueriedData?.medicalHistory) {
      dispatchMedicalHistory(consultationQueriedData.medicalHistory);
    }
  }, [consultationQueriedData]);

  useEffect(() => {
    if (!isMedical) {
      dispatch(clearMedical());
    }
  }, [isMedical]);

  const dispatchMedicalHistory = (fetchedMedicalHistory: any) => {
    console.log("medical history from server", fetchedMedicalHistory);
    if (fetchedMedicalHistory?.medical?.length > 0) {
      dispatch(setIsMedicals(true));
      fetchedMedicalHistory?.medical.map((medical: any, index: number) => {
        medical?.motif?.map((medicalMotif: any) => {
          dispatch(
            setMedicalMotifs({
              index,
              value: medicalMotif,
            })
          );
        });
        medical?.year &&
          dispatch(
            setMedicalYear({
              index: index,
              value: medical?.year,
            })
          );
      });
    }
    if (fetchedMedicalHistory?.surgical?.length > 0) {
      dispatch(setIsSurgicals(true));
      fetchedMedicalHistory?.surgical.map((surgical: any, index: number) => {
        surgical?.motif?.map((surgicalMotif: any) => {
          dispatch(
            setSurgicalMotifs({
              index: index,
              value: surgicalMotif,
            })
          );
        });
        surgical?.year &&
          dispatch(
            setSurgicalYear({
              index: index,
              value: surgical?.year,
            })
          );
      });
    }

    if (fetchedMedicalHistory?.family) {
      if (fetchedMedicalHistory?.family?.selected) {
        fetchedMedicalHistory?.family?.selected?.map(
          (selected: any, index: number) => {
            dispatch(setFamilySelected(selected));
          }
        );
      }
      if (fetchedMedicalHistory?.family?.others) {
        fetchedMedicalHistory?.family?.others?.map(
          (other: any, index: number) => {
            dispatch(setFamilyOthers(other));
          }
        );
      }
    }
    if (fetchedMedicalHistory?.toxic) {
      if (fetchedMedicalHistory?.toxic?.tabac) {
        dispatch(setisTabacConsuming(true));

        fetchedMedicalHistory?.toxic?.tabac.boxPerYear &&
          dispatch(
            setToxicsTabacBoxPerYear(
              fetchedMedicalHistory?.toxic?.tabac.boxPerYear
            )
          );
        fetchedMedicalHistory?.toxic?.tabac.duration &&
          dispatch(
            setToxicsTabacDuration(fetchedMedicalHistory?.toxic?.tabac.duration)
          );
        fetchedMedicalHistory?.toxic?.tabac.perDay &&
          dispatch(
            setToxicsTabacCigarttesPerDay(
              fetchedMedicalHistory?.toxic?.tabac.perDay
            )
          );
        fetchedMedicalHistory?.toxic?.tabac.type &&
          dispatch(
            setToxicsTabacType(fetchedMedicalHistory?.toxic?.tabac.type)
          );
      }
      if (fetchedMedicalHistory?.toxic?.alcohol) {
        if (fetchedMedicalHistory?.toxic?.alcohol) {
          const isAlcoholic =
            fetchedMedicalHistory?.toxic?.alcohol?.isAlcoholic;
          dispatch(setIsAlcoholic(isAlcoholic));
          dispatch(setToxicsAlcohol(isAlcoholic));
        }
      }
      if (fetchedMedicalHistory?.toxic?.others) {
        dispatch(sethasOtherSubs(true));
        dispatch(setToxicsOthers(fetchedMedicalHistory?.toxic?.others.nature));
      }
    }
    if (fetchedMedicalHistory?.allergy) {
      dispatch(setIsAllergic(true));
      fetchedMedicalHistory?.allergy?.map((alergy: any, index: number) => {
        dispatch(setAllergy(alergy));
      });
    }
  };

  useEffect(() => {
    if (storedMedical?.length > 0) {
      setIsMedical(true);
      dispatch(setIsMedicals(true));
      setMedicalList([...storedMedical]);
    } else {
      setMedicalList([{ year: "", motif: [] }]);
    }
  }, [storedMedical]);

  return (
    <div className="chirurgicaux relative mb-10 mt-8">
      <h1 className="text-[#5E5E5E] -mb-4">Médicaux :</h1>
      <div className="center flex gap-20  items-start">
        <div className="flex gap-4 items-center mt-8">
          <RadioInput
            id="oui1"
            name="isMedical"
            label="Oui"
            onChange={() => {
              setIsMedical(true);
            }}
            checked={isMedical}
          />
          <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
          <RadioInput
            id="non1"
            name="isMedical"
            onChange={() => {
              setIsMedical(false);
            }}
            checked={!isMedical}
            label="Non"
          />
        </div>
        <div style={{ width: "100%" }}>
          {isMedical &&
            medicalList &&
            medicalList?.map((medical: any, index: number) => {
              return (
                <div
                  className="w-100 flex gap-8 items-top  relative"
                  key={index}
                >
                  {" "}
                  <div className="w-[65%]">
                    <SearchableInput
                      name={SLICE.MEDICAUX}
                      index={index}
                      placeholder="Maladies"
                      dataState={SELECT_DATA_STATE.CHIEF_COMPLAINTS}
                      actions={[setMedicalMotifs]}
                      label="Maladies"
                      style="my-4"
                    />
                    <Tag
                      properties={{
                        id: 0,
                        type: "tag",
                        step: 1,
                        index: index,
                        containerStyle:
                          "mt-2 mb-4 substance-list flex flex-wrap gap-2",
                        tagStyle:
                          "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                        dataState: TAG_DATA_STATE.MEDICAL_MOTIFS,
                      }}
                    />
                  </div>
                  <div className="year mt-4  w-[35%] ">
                    <h1 className="text-[#5E5E5E] text-sm ">Année</h1>
                    <SelectListBox
                      name="annee"
                      label=""
                      index={index}
                      defaultValue={medical.year}
                      data={years}
                      actions={[setMedicalYear]}
                      useCase={SLICE.MEDICAUX}
                      dataState={SELECT_DATA_STATE.YEAR}
                      placeholder="Année"
                      style="mt-4 "
                    />
                  </div>
                  {medicalList?.length > 1 && (
                    <div className="">
                      <button
                        onClick={() => {
                          setMedicalList((prevList: any) => {
                            const newList = [...prevList];
                            newList.splice(index, 1);
                            return newList;
                          });
                          dispatch(spliceMedicalData(index));
                        }}
                      >
                        <TrashIcon className=" absolute top-14 right-0 h-6 w-6 text-[#5E5E5E]" />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {isMedical && medicalList && medicalList?.length > 0 && (
          <button
            onClick={() => {
              setMedicalList([...medicalList, { year: "", motif: [] }]);
              dispatch(addNewMedical());
            }}
            className="absolute flex items-center right-0 -bottom-[3rem] rounded-lg h-9 px-2 bg-[#1AA5C0]"
          >
            <p className="text-white mr-1">Ajouter</p>
            <PlusSvg width={25} />{" "}
          </button>
        )}
      </div>
    </div>
  );
}
