import { ChangeEvent, Fragment, MouseEvent, useEffect, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { CloudArrowDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  ChatBubbleBottomCenterIcon,
  DocumentTextIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setSpecialistConclusion } from "../../features/specialistConclusion/specialistConclusionSlice";
import {
  useUpdateConsultationDataMutation,
  useGetConsultationDataQuery,
} from "../../features/consultationData/consultationDataApi";
import toast from "react-hot-toast";
import APP_CONSTANTS from "../../constants";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import { MedicationPrescriptions } from "../medicationsPrescription";
import { DiagnosticsTree } from "../diagnostics/DiagnosticsTree";
import Transcript from "./Transcript";
import { useDebounce } from "./useDebounce";

export interface IDrawerProps {
  open?: boolean;
  setHangUp?: any;
  hangUp?: any;
  setDrawerOpen: any;
}

export default function Drawer({
  open,
  setDrawerOpen,
  setHangUp,
  hangUp,
}: IDrawerProps) {
  const navigate = useNavigate();
  const { openDynamicDialog, closeDynamicDialog } = useDynamicDialog();

  const dispatch = useDispatch();

  /* */
  const specialistConclusion = useSelector(
    (state: any) => state.specialistConclusion.text
  );

  const [
    setConsultationData,
    { isLoading: isPatchLoading, isSuccess: isPatchSuccess },
  ] = useUpdateConsultationDataMutation();

  const onGoingConsultationId = useSelector(
    (state: any) => state.consultationData?.lastPatientOngoingConsultation?.id
  );
  const storedUser = useSelector((state: any) => state.auth?.user);
  const [toggle, setToggle] = useState(false);
  const [finalConsultationText, setFinalConsultationText] = useState("");
  const [canReload, setCanReload] = useState(false);
  const [consultationId, setConsultationId] = useState(null);
  const { data: consultationData, isLoading: isGetLoading } =
    useGetConsultationDataQuery(Number(consultationId));

  const [note, setNote] = useState<string>(specialistConclusion);
  const debouncedNote = useDebounce(note, 2500);

  useEffect(() => {
    if (debouncedNote?.trim() != "") {
      dispatch(setSpecialistConclusion(debouncedNote));
      setConsultationData(
        {
          id: consultationId,
          body: {
            specialistConclusion: debouncedNote,
            specialist: { id: parseInt(storedUser?.doctor?.id, 10) },
            indexStep: 7,
          },
        },
        1
      );

      setCanReload(true);

      if (isPatchSuccess) {
        setCanReload(true);
        toast.success("Compte rendu enregistré avec succès");
      }
    }
  }, [debouncedNote]);

  useEffect(() => {
    console.log("canReload", canReload);

    const handleBeforeUnload = (event:any) => {
      if (!canReload) {
        const message = 'Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [canReload]);

  useEffect(() => {

    

    const consultationMetadata = JSON.parse(
      localStorage.getItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`
      ) || ""
    );
    console.log(consultationMetadata, "metadata");
    setConsultationId(consultationMetadata?.patientOngoingConsultation?.id);
   
  }, []);



  useEffect(() => {
    if (consultationData?.specialistConclusion) {
      console.log(consultationData, "consultationData");
      setTextareaValue(consultationData?.specialistConclusion);
      setNote(consultationData?.specialistConclusion);
    }

    // setTextareaText(consultationData);
  }, [consultationData]);

  /**
   *
   */
  const handleAddPrescriptionClick = () => {
    openDynamicDialog({
      message: "",
      title: "",
      handleConfirm: () => {},
      size: "xl",
      component: (
        <>
          <MedicationPrescriptions />
        </>
      ),
    });
  };

  /**
   *
   */
  const handleAddPrincipalDiagnosticClick = () => {
    openDynamicDialog({
      message: "",
      title: "Diagnostic principal",
      handleConfirm: () => {},
      size: "xl",
      component: (
        <>
          <DiagnosticsTree limitSelection={1} setValues={() => {}} />
        </>
      ),
    });
  };

  /**
   *
   */
  const handleAddAssocialtedDiagnosticsClick = () => {
    openDynamicDialog({
      message: "",
      title: "Diagnostics associés",
      handleConfirm: () => {},
      size: "xl",
      component: (
        <>
          <DiagnosticsTree limitSelection={3} setValues={() => {}} />
        </>
      ),
    });
  };

  useEffect(() => {
    console.log(
      "getted specialist conclusion from store",
      specialistConclusion
    );
    if (specialistConclusion?.length) setTextareaValue(specialistConclusion);
    // setNote(specialistConclusion)
  }, []);
  /**
   *
   * @param e
   */

  // Define the state for the textarea value
  const [textareaValue, setTextareaValue] = useState<string>(""); // Set the initial value accordingly

  // Event handler for textarea change
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    // Update the state with the new textarea value
    setTextareaValue(event.target.value);
    if (event.target.value != specialistConclusion && event.target.value !== "")
      dispatch(setSpecialistConclusion(event.target.value));
    setNote(event.target.value);
    setCanReload(false);
    event.target.style.height = event.target.scrollHeight + "px";
  };

  // Function to set the textarea value programmatically
  const setTextareaText = (newText: string) => {
    setTextareaValue(newText);
  };

  const handleContextMenu = (event: MouseEvent<HTMLTextAreaElement>) => {
    // Prevent the default context menu behavior
    event.preventDefault();
  };

  return (
    <div className="relative overflow-hidden w-[30rem] h-[80vh] ml-5 bg-white shadow-xl rounded-l-lg">
      <div className="flex items-center justify-between bg-[#1AA5C026] w-full rounded-t-lg p-3">
        <h2 className="text-lg text-[#1AA5C0]">
          Compte rendu de la consultation
        </h2>
        {/* <span className={`${navigator.onLine ?"":"bg-red-100 text-red-800  dark:bg-red-900 dark:text-red-300"} text-xl font-bold me-2 px-2.5 py-0.5 rounded`}>{navigator.onLine?"Connected":"OFFline"}</span> */}

        <XMarkIcon
          className="text-[#1AA5C0] w-6 cursor-pointer"
          onClick={() => {
            setDrawerOpen(false);
          }}
        />
      </div>
      <div className="mt-4 px-4 py-6 relative">
        {/* Your content goes here */}
        {/* Replace this with your content */}

        {specialistConclusion ? (
          <textarea
            onChange={handleTextareaChange}
            defaultValue={specialistConclusion}
            value={textareaValue}
            //value={toggle ? specialistConclusion : ""}
            rows={4}
            id="mainReport"
            className="relative block p-2.5 w-full text-[20px] text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
            placeholder="Rédigez votre compte rendu..."
            onContextMenu={handleContextMenu}
          ></textarea>
        ) : (
          <div className=" h-[103px] relative  p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 flex justify-center items-center">
            <Spinner />
          </div>
        )}

        <Transcript
          toggle={toggle}
          setToggle={setToggle}
          finalConsultationText={finalConsultationText}
          setFinalConsultationText={setFinalConsultationText}
          textareaValue={textareaValue}
          setTextareaValue={setTextareaValue}
          consultationId={consultationId}
          specialistId={parseInt(storedUser?.doctor?.id, 10)}
          canReload={canReload}
           setCanReload={setCanReload}
        />
        {/* prescriptions */}
        {/* <div className="m-5">
          <div>
            <button
              className="w-full text-white  bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"

              onClick={handleAddPrescriptionClick}>Ajouter une préscipription</button>

          </div>
        </div> */}
        {/* diagnostics */}
        {/* <div>
          <button onClick={handleAddPrincipalDiagnosticClick} className="w-full text-white  bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center   mb-2">Ajouter un diagnostic principal</button>
          <button onClick={handleAddAssocialtedDiagnosticsClick} className="w-full text-white  bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center   mb-2">Ajouter des diagnostics associés</button>
        </div> */}
      </div>
    </div>
  );
}
