import { NOTIFICATION_TYPE } from "../../enums/notification";
import { Popover, Transition } from "@headlessui/react";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useNavigate } from "react-router";
import WebSocketService from "../../services/Notification/socketService";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications, setRefreshCalendar } from "../../features/notifications/notificationsSlice";
import toast from "react-hot-toast";
import moment from "moment";
import { setConsultationMetadata } from "../../features/consultationMetadata";
import { setMotif } from "../../features/sidebar/SidebarSlice";
import APP_CONSTANTS from "../../constants";
import { NotifiedObject, updateCurrentNotifiedConsultation, updateNotifiedConsultations } from "../../features/call/notifiedConsultationSlice";
import { RootState } from "../../store";
import { setBoxId, setGeneralistId, setCenterId } from "../../features/teleconsultation/CallInfosSlice";
import { setOnlineUsers } from "../../features/auth";
// import { Howl } from 'howler';
// import ringtoneSound from "../../assets/ringtone.mp3"
interface Notification {
  message: string;
  type: string;
  icon?: React.ReactNode;
  title: string;
}
export interface INotificationsProps {
  PopoverButton: React.ReactNode;
  Notifs: [Notification] | null;
}


/**
 * 
 * @param param0 
 * @returns 
 */
export default function Notifications({
  PopoverButton,
  Notifs,
}: INotificationsProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onlineUsersArr, setOnlineUsersArr] = React.useState<Array<any>>([]);

  const notifications = useSelector((state: any) => state.notifications.notifs);
  const notifiedConsultationsArr = useSelector((state: any) => state.notifiedConsultation.notifiedConsultations);



  React.useEffect(()=> {
    if(onlineUsersArr) dispatch(setOnlineUsers(onlineUsersArr))
  }, [onlineUsersArr])

  const notify = (acceptText: string, RefuseText: string, roomDetails: any) => {
    // sound.play();

    toast.custom(
      (t: any) => (
        <div
          className={`${t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 bg-[#1aa4c0e0]`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                {/* <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                /> */}
              </div>
              <div className="ml-3 flex-1">
                <p className="mt-1 text-sm text-gray-500">
                  Vous un appel de télé-expertise
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => {
                const targetURL = `/doctor/call/${roomDetails.id}`;
                // sound.stop();
                navigate(targetURL, {
                  state: { uid: roomDetails.id },
                });
                toast.dismiss("776655");
                // createRoom(patientId);
                // demarrerConsultation();
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-bold text-white hover:text-indigo-500 focus:outline-none focus:ring-2  bg-green-500"
            >
              {acceptText}
            </button>
            <button
              onClick={() => {
                toast.dismiss("776655");
                // sound.stop();

                // dismissPropose();
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-bold text-white hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 bg-red-500"
            >
              {RefuseText}
            </button>
          </div>
        </div>
      ),
      {
        id: "776655",
        duration: 1800000 // 30 minutes
      }
    );
  };

  const notifiedConsultationsIds = useSelector(
    (state: RootState) => state?.notifiedConsultation.notifiedConsultationsIds
  );

  React.useEffect(() => {
    console.log("this was the notifications", notifiedConsultationsIds)
  }, [notifiedConsultationsIds])

  /**
   * 
   * @param notification 
   */
  const handleNotification = (notificationObj: any) => {

    const notification = notificationObj.notif;

    if (notification.type == NOTIFICATION_TYPE.APPOINTMENT)
      dispatch(setRefreshCalendar(true));

    // dispatch(setMotif({userId: notification.notif.userId}));
    // localStorage.setItem("generalistUserId", notification.userId);
    dispatch(setGeneralistId(notification.userId));
    dispatch(setBoxId(notificationObj?.stationId));

    dispatch(setCenterId(notificationObj?.centerId));

    toast.custom(
      (t) => {
        let type = "";
        switch (notification.type) {
          case NOTIFICATION_TYPE.APPOINTMENT:
            {

              type = "Rendez-vous";

            }
            break;

          case NOTIFICATION_TYPE.TELECONSULTATION_REMINDER: {
            // the consultation that is notified
            const notifiedConsultation: NotifiedObject = {
              consultationId: notificationObj?.consultationId,
              stationId: notificationObj.stationId,
              generalistId: notification?.userId
            };

            // list of consultations that are notified
            let notifiedConsultations = [...notifiedConsultationsArr];

            const existingConsultationIndex = notifiedConsultations.findIndex(
              (consultation) => consultation.consultationId === notifiedConsultation.consultationId
            );

            if (existingConsultationIndex === -1) {
              // Consultation does not exist, add it to the list
              console.log(`Consultation ${notifiedConsultation.consultationId} was not notified.`);
              dispatch(updateNotifiedConsultations([...notifiedConsultations, notifiedConsultation]));
              dispatch(updateCurrentNotifiedConsultation(notifiedConsultation));
            } else {
              // Consultation exists, update it in the list
              console.log(`Consultation ${notifiedConsultation.consultationId} is already notified.`);
              notifiedConsultations[existingConsultationIndex] = notifiedConsultation;
              dispatch(updateNotifiedConsultations([...notifiedConsultations]));
              dispatch(updateCurrentNotifiedConsultation(notifiedConsultation));
            }
            break;
          }

        }

        return (
          <div
            className={
              " w-[30rem]  bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
            }
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <CalendarDaysIcon className="w-8 text-[#1AA5C0] " />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-[#1aa4c0e0]">

                    {notification.type == NOTIFICATION_TYPE.TELECONSULTATION_REMINDER ? "Patient prêt pour télé-expertise" : ""}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {moment(notification.createdAt).locale("fr").fromNow()}
                  </p>
                  <p className="mt-1 text-sm text-black-500">
                    {notification.content}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(notification.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Fermer
              </button>
            </div>
          </div>
        );
      },
      {
        id: notification.id,
        duration: 10000


      }
    );

  };


  // connecting socket
  WebSocketService.connect();

  // subscribing to events
  WebSocketService.subscribeToNotifications(handleNotification);
  WebSocketService.subscribeToConnectedUsers((data: string) => {
    if(data) setOnlineUsersArr(JSON.parse(data));
   });

  WebSocketService.joinCall((data) => {
    console.log("data", data);
    notify("Accepter", "Refuser", data);
  });

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            {PopoverButton}
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  left-1/2 z-50 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 ">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-4 h-[30rem] overflow-auto">
                    {notifications && notifications?.length > 0 ? (
                      <>
                        {notifications?.map((notif: any) => {
                          let type = "";
                          switch (notif.type) {
                            case NOTIFICATION_TYPE.APPOINTMENT:
                              type = "Rendez-vous";
                              break;
                          }
                          return (
                            <div
                              onClick={() => {
                                // switch(notif.type){
                                //   case NOTIFICATION_TYPE.APPOINTMENT:
                                //     navigate("/doctor/calendar/130")
                                //     break;
                                // }
                              }}
                              className="cursor-pointer flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="flex">
                                <div>
                                  <CalendarDaysIcon className="w-6 text-[#1AA5C0] mr-2 mt-1" />
                                </div>
                                <div>
                                  {" "}
                                  <span className="flex items-center">
                                    <span className="text-xs font-medium text-[#1AA5C0]">
                                      {type}
                                    </span>
                                  </span>
                                  <span className="flex items-center">
                                    <span className="text-sm font-medium text-gray-900">
                                      {type}
                                    </span>
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    {notif?.content}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="flex w-full justify-center text-gray-500">
                        Aucune Notification
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
