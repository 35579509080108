import React, { useEffect, useMemo, useState } from "react";
import MedicalHistoryButton from "./Inputs";
import { ReactComponent as Edit } from "../../assets/editMedicalHistory.svg";
import { useQuery } from "react-query";
import styled from "styled-components";
import {
  getPatientMedicalHistory,
  postMedicalHistory,
} from "../../axiosAPI/medical-history";
import Table from "./Table";
import { useParams } from "react-router";
import { Spinner } from "@material-tailwind/react";
import { IPatient } from "../../interfaces/patient";

const UlStyled = styled.div`
  /* list-style: none; */
  background-color: #f5f5f5;
  padding: 1rem;
  margin: 0;
  border-radius: 0.5rem;
  li {
    color: #5e5e5e;
  }
  li::marker {
    color: #5e5e5e;
    margin-left: 4rem;
    /* Replace 'red' with the desired color value */
  }
`;
type AntecedantProps = {
  label?: string;
  value: string[];
  showLabel?: boolean;
};

type historyType = {
  date: string;
  time: string;
  doctor: string;
  modificationText: string;
};
interface IMedicalHistoryProps {
  patientId?: number;
}
const MedicalHistory = (props: IMedicalHistoryProps) => {
  const [page, setPage] = useState(1);
  const [medicalHistory, setMedicalHistory] = useState<
    Record<string, string[]>
  >({});
  const [patientId, setPatientId] = useState("");
  const [data, setData] = useState<IPatient>();
  const params = useParams();

  useEffect(() => {
    getPatientMedicalHistory(props.patientId ?? params?.id)
      .then((res) => {
        setData(res);
        console.log("data", res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getPatientMedicalHistory(+parseInt(params.id || "", 10)).then((res) => {
      console.log(res);
    });
  }, []);
  const medicalHistoryData = useMemo(() => {
    if (data?.medicalHistory) {
      return data?.medicalHistory;
    } else return {} as any;
  }, [data]);

  const history = useMemo(() => {
    if (!data?.medicalHistory?.history) return [];
    return data?.medicalHistory?.history?.map((x: any) => ({
      date: x.created?.split(" ")[0],
      time: x.created?.split(" ")[1],
      doctor: ` ${x.doctor.firstName} ${x.doctor.lastName}`,
    }));
  }, [data]);

  useEffect(() => {
    setPatientId(params.id || "");
  }, [data]);

  return (
    <div className="w-full h-full flex flex-col">
      {page == 1 ? (
        <>
          <div className="grid grid-cols-4 [&>*]:h-[40rem] [&>*]:max-h-[40rem] [&>*]:rounded-md  [&>*]:border-2 [&>*]:border-black/10 gap-3">
            <span className="flex  flex-col Perso">
              <span className="h-16 bg-[#1AA5C0] text-white flex items-center pl-4 text-lg font-medium">
                Personnels
              </span>
              <span className="flex-1 flex flex-col overflow-y-scroll">
                <span className="flex-1 flex flex-col p-4  gap-2 ">
                  {medicalHistoryData && (
                    <>
                      {/* inline */}
                      {medicalHistoryData?.medical?.length > 0 && (
                        <>
                          <label className="text-[#1AA5C0] text-lg">
                            {"medicaux"}
                          </label>
                          <UlStyled>
                            {medicalHistoryData.medical
                              .filter(
                                (item: any) =>
                                  item?.motif?.length > 0 && item?.year !== ""
                              )
                              .reduce(
                                (uniqueItems: any[], currentItem: any) => {
                                  const existingItemIndex =
                                    uniqueItems.findIndex(
                                      (item: any) =>
                                        item.year === currentItem?.year
                                    );

                                  if (existingItemIndex === -1) {
                                    uniqueItems.push({
                                      year: currentItem?.year,
                                      motif: [...new Set(currentItem?.motif)],
                                    });
                                  } else {
                                    uniqueItems[existingItemIndex].motif = [
                                      ...new Set([
                                        ...uniqueItems[existingItemIndex].motif,
                                        ...currentItem?.motif,
                                      ]),
                                    ];
                                  }

                                  return uniqueItems;
                                },
                                []
                              )
                              .map((x: any, index: number) => (
                                <li key={index}>{`${x?.motif?.join(", ")} - ${
                                  x?.year
                                }`}</li>
                              ))}
                          </UlStyled>
                        </>
                      )}

                      {/*  */}
                      {/* nested */}
                      {medicalHistoryData?.surgical?.length > 0 && (
                        <>
                          <label className="text-[#1AA5C0] text-lg">
                            {"chirurgicaux"}
                          </label>
                          <UlStyled>
                            {medicalHistoryData.surgical
                              .reduce(
                                (uniqueItems: any[], currentItem: any) => {
                                  const existingItemIndex =
                                    uniqueItems.findIndex(
                                      (item: any) =>
                                        item.year === currentItem.year
                                    );

                                  if (existingItemIndex === -1) {
                                    uniqueItems.push({
                                      year: currentItem.year,
                                      motif: [...new Set(currentItem.motif)],
                                    });
                                  } else {
                                    uniqueItems[existingItemIndex].motif = [
                                      ...new Set([
                                        ...uniqueItems[existingItemIndex].motif,
                                        ...currentItem.motif,
                                      ]),
                                    ];
                                  }

                                  return uniqueItems;
                                },
                                []
                              )
                              .map((x: any, index: number) => (
                                <li key={index}>{`${x.motif.join(", ")} - ${
                                  x.year
                                }`}</li>
                              ))}
                          </UlStyled>
                        </>
                      )}

                      {/*  */}
                    </>
                  )}
                </span>
              </span>
            </span>
            <span className="flex  flex-col medicaux">
              <span className="h-16 bg-[#1AA5C0] text-white flex items-center pl-4 text-lg font-medium">
                Familiaux
              </span>
              <span className="flex-1 flex flex-col overflow-y-scroll">
                <span className="flex-1 flex flex-col p-4  gap-2">
                  {/* inline */}
                  {medicalHistoryData?.family && (
                    <>
                      <UlStyled>
                        {medicalHistoryData?.family?.selected?.map(
                          (item: string, index: number, arr: string[]) =>
                            arr.indexOf(item) === index && (
                              <li key={index}>{item}</li>
                            )
                        )}
                      </UlStyled>
                      <UlStyled>
                        {medicalHistoryData?.family?.others?.map(
                          (item: string, index: number, arr: string[]) =>
                            arr.indexOf(item) === index && (
                              <li key={index}>{item}</li>
                            )
                        )}
                      </UlStyled>
                    </>
                  )}
                  {/*  */}
                </span>
              </span>
            </span>
            <span className="flex  flex-col Habitudes toxiques">
              <span className="h-16 bg-[#1AA5C0] text-white flex items-center pl-4 text-lg font-medium">
                Habitudes toxiques
              </span>
              <span className="flex-1 flex flex-col overflow-y-scroll">
                <span className="flex-1 flex flex-col p-4  gap-2">
                  {/* inline */}
                  {medicalHistoryData && medicalHistoryData?.toxic ? (
                    <>
                      <UlStyled>
                        {Object.keys(medicalHistoryData?.toxic).length ===
                          0 && <span>Pas d'habitude toxique</span>}
                        {Object.keys(
                          medicalHistoryData?.toxic
                            ? medicalHistoryData?.toxic
                            : []
                        ).length > 0 &&
                          Object.keys(
                            medicalHistoryData?.toxic
                              ? medicalHistoryData?.toxic
                              : []
                          )?.map((key) => (
                            <>
                              {key == "others" && (
                                <label className="text-[#1AA5C0] text-lg">
                                  Autres
                                </label>
                              )}
                              {key == "alcohol" && (
                                <label className="text-[#1AA5C0] text-lg">
                                  Alcool
                                </label>
                              )}
                              {key == "tabac" &&
                              (medicalHistoryData?.toxic[key]?.perDay > 0 ||
                                medicalHistoryData?.toxic[key]?.boxPerYear >
                                  0 ||
                                medicalHistoryData?.toxic[key]?.duration >
                                  0) ? (
                                <label className="text-[#1AA5C0] text-lg">
                                  {`Tabac - (${
                                    medicalHistoryData?.toxic[key].type
                                      ? medicalHistoryData?.toxic[key].type
                                      : "Actif"
                                  })`}
                                </label>
                              ) : (
                                ""
                              )}
                              <br></br>

                              {/* tabacco values */}
                              {key == "tabac" && (
                                <ul>
                                  {medicalHistoryData?.toxic[key]?.duration >
                                    0 &&
                                    ["passif", "sevre"].includes(
                                      medicalHistoryData?.toxic[key]?.type
                                    ) && (
                                      <li>
                                        -{" "}
                                        {
                                          medicalHistoryData?.toxic[key]
                                            ?.duration
                                        }{" "}
                                        Années
                                      </li>
                                    )}
                                  {medicalHistoryData?.toxic[key]?.perDay > 0 &&
                                    [undefined, "actif"].includes(
                                      medicalHistoryData?.toxic[key]?.type
                                    ) && (
                                      <li>
                                        -{" "}
                                        {medicalHistoryData?.toxic[key]?.perDay}{" "}
                                        cigarettes par jour
                                      </li>
                                    )}
                                  {medicalHistoryData?.toxic[key]?.boxPerYear >
                                    0 &&
                                    [undefined, "actif"].includes(
                                      medicalHistoryData?.toxic[key]?.type
                                    ) && (
                                      <li>
                                        -{" "}
                                        {
                                          medicalHistoryData?.toxic[key]
                                            ?.boxPerYear
                                        }{" "}
                                        paquets par année
                                      </li>
                                    )}
                                </ul>
                              )}
                              {/* tabacco values */}

                              {key == "alcohol" && (
                                <>Le patient consome l'alcool</>
                              )}
                              {key == "others" && (
                                <div>
                                  - {medicalHistoryData?.toxic[key]?.nature}
                                </div>
                              )}
                            </>
                          ))}
                      </UlStyled>
                    </>
                  ) : (
                    ""
                  )}
                  {/*  */}
                </span>
              </span>
            </span>
            {/*  */}
            <span className="flex  flex-col Allergie">
              <span className="h-16 bg-[#1AA5C0] text-white flex items-center pl-4 text-lg font-medium">
                Allergiques
              </span>
              <span className="flex-1 flex flex-col overflow-y-scroll">
                <span className="flex-1 flex flex-col p-4 gap-2">
                  {/* inline */}
                  {medicalHistoryData?.allergy?.length > 0 && (
                    <>
                      <label className="text-[#1AA5C0] text-lg">
                        {"Allergies"}
                      </label>
                      <UlStyled>
                        {medicalHistoryData.allergy.reduce(
                          (uniqueAllergies: JSX.Element[], allergy: any) => {
                            if (
                              !uniqueAllergies.find(
                                (item) => item.key === allergy
                              )
                            ) {
                              uniqueAllergies.push(
                                <li key={allergy}>{allergy}</li>
                              );
                            }
                            return uniqueAllergies;
                          },
                          []
                        )}
                      </UlStyled>
                    </>
                  )}
                  {/*  */}
                </span>
              </span>
            </span>
            {/*  */}
            {/*  */}
            {/* <span className="flex  flex-col obstruco">
              <span className="h-16 bg-[#1AA5C0] text-white flex items-center pl-4 text-lg font-medium">
                Obstétricaux
              </span>
              <span className="flex-1 flex flex-col overflow-y-scroll">
                <span className="flex-1 flex flex-col p-4  gap-2 ">
                  <>
                    <UlStyled>
                      {medicalHistoryData?.obstetric?.map((x: any, index: number) => (
                        <li key={index}>{x}</li>
                      ))}
                    </UlStyled>
                  </>
                </span>
              </span>
            </span> */}
            {/*  */}
          </div>
        </>
      ) : (
        <div className=" h-full ">
          <div className="flex justify-start py-4 items-center text-[#20A8D3] font-semibold gap-2 text-xl">
            <img
              width={50}
              src="/medicalHistory/back.svg"
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setPage(1);
              }}
            />
            <span>Historique des modification </span>
          </div>
          <div className="h-4/5 w-full ">
            <Table
              rows={history || []}
              header={[
                "Date",
                "L’heure",
                "Medecin traitant",
                "Modification effectué",
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalHistory;
