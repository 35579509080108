
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);
 const doctorApi = createApi({
    reducerPath: "doctorApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SERVER_URL,
         prepareHeaders: (headers) => {
             headers.set("Authorization", `Bearer ${token}`);
             return headers;
         },
    }),

    endpoints: (builder) => ({
        // GET 
        getConsultationByDoctorID: builder.query<[], number>({
            query: (id) => `/consultation/?where={"doctorId":${id}}&include=patient.user`,
          
        }),
        getDoctorById: builder.query<[], number>({
           query: (id) => `/doctor/${id}`,   
       }),
       
    }),
});

export const { useGetConsultationByDoctorIDQuery, useGetDoctorByIdQuery } = doctorApi;

export default doctorApi;
